<template>
  <div class="panel">
    <p class="panel-heading">Service options</p>
    <div class="p-4">
      <div class="field">
        <label class="label" for="root_dir">Root directory</label>
        <div class="control">
          <input type="text" class="input" name="root_dir" id="root_dir" v-model="rootDir">
        </div>
      </div>
      <div class="field">
        <label class="label" for="port">Port</label>
        <div class="control">
          <input type="text" class="input" name="port" id="port" v-model="port">
        </div>
      </div>
      <div class="field">
        <label class="label" for="healthcheck">Health check</label>
        <div class="control">
          <input type="text" class="input" name="healthcheck" id="healthcheck" v-model="healthcheck">
        </div>
      </div>
      <button class="button is-primary" @click="saveOptions">Next: connect AWS</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const saveOptionsUrl = 'https://run.mocky.io/v3/2fe9dd65-7555-4bed-8b98-6589f5f970c6';

export default {
  name: 'Setup',
  props: ['setupId'],
  data() {
    return {
      rootDir: './',
      port: 8000,
      healthcheck: '/'
    }
  },
  methods: {
    async saveOptions() {
      await axios.post(saveOptionsUrl, {
        root_dir: this.rootDir,
        port: this.port,
        healthcheck: this.healthcheck
      });
      this.$router.push(`/setup/${this.setupId}/aws`);
    }
  }
}
</script>
