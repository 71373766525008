import axios from 'axios';
import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth0 = new Auth0Client({
    domain: 'dev-0gsos99z.eu.auth0.com',
    client_id: 'HsPsj3KfBU6ne5DQ4wEgaIsXxv8dnqkA',
    audience: 'tabilly.digger.dev',
    redirect_uri: window.location.origin
});

window.addEventListener('load', async () => {
  const params = window.location.search;
  if (params.includes("code=") && params.includes("state=")) {
    await auth0.handleRedirectCallback();
    window.history.replaceState({}, document.title, window.location.pathname);
  }
});

axios.interceptors.request.use(async (config) => {
  console.log('interceptor called');
  try {
    const token = await auth0.getTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
  } catch(e) {
    console.error(e);
  }
  return config;
});
