<template>
  <div class="panel">
    <p class="panel-heading">Connect your AWS account</p>
    <div class="p-4">
      <div class="field">
        <label class="label" for="key_id">Key ID</label>
        <div class="control">
          <input class="input" type="text" name="key_id" id="key_id" v-model="keyId">
        </div>
      </div>
      <div class="field">
        <label class="label" for="key_secret">Secret</label>
        <div class="control">
          <input class="input" type="password" name="key_secret" id="key_secret" v-model="keySecret">
        </div>
      </div>
      <button class="button is-primary" @click="deploy">Deploy</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const deployUrl = 'https://run.mocky.io/v3/ed544067-557c-4a6c-807c-32043bb394f0';

export default {
  name: 'AwsKeys',
  props: ['setupId'],
  data() {
    return {
      keyId: '',
      keySecret: ''
    }
  },
  methods: {
    async deploy() {
      await axios.post(deployUrl, {
        setup: this.setupId,
        aws_key: this.keyId,
        aws_secret: this.keySecret
      });
      this.$router.push(`/setup/${this.setupId}/nextsteps`);
    }
  }
}
</script>
