<template>
  <div class="panel">
    <p class="panel-heading">Import GitHub repository</p>
    <div class="panel-block" v-for="repo in repositories" :key="repo.name">
      <div class="container">
        <button class="button is-primary is-pulled-right" @click="importRepo(repo)">Import</button>
        <p>{{repo.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const reposUrl = 'https://run.mocky.io/v3/9dd6d142-9a07-4128-bd27-1f34344069fd';
const importUrl = 'https://run.mocky.io/v3/84b31b8d-4231-4e01-b61c-ec86b8f7457a';

export default {
  data() {
    return {
      repositories: []
    };
  }, 
  async beforeMount() {
    try {
      const response = await axios.get(reposUrl);
      this.$data.repositories = response.data.repositories;
    } catch(e) {
      console.error(e);
    }    
  },
  methods: {
    importRepo: async function() {
      const response = await axios.post(importUrl);
      const setupId = response.data.setup_id;
      this.$router.push(`/setup/${setupId}/options`);
    }
  }
}

</script>
