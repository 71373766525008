import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Home from '../views/Home.vue'
import RepoList from '../views/RepoList.vue'
import Setup from '../views/Setup.vue'
import AwsKeys from '../views/AwsKeys.vue'
import NextSteps from '../views/NextSteps.vue'
import Logout from '../views/Logout.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/repos',
    name: 'Repos',
    component: RepoList
  },
  {
    path: '/setup/:setupId/options',
    name: 'Setup',
    component: Setup,
    props: true
  },
  {
    path: '/setup/:setupId/aws',
    name: 'AWS',
    component: AwsKeys,
    props: true
  },
  {
    path: '/setup/:setupId/nextsteps',
    name: 'NextSteps',
    component: NextSteps,
    props: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
