<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <p class="title">
        Your infrastructure is being created
      </p>
    </div>
  </section>
  <p class="block">This may take a few minutes</p>
  <p class="block">In the meantime, install the CLI: <b>npm install diggercli</b></p>
  <p class="block">Or, check the progress in <a href="console.aws.amazon.com">your AWS console</a></p>
</template>