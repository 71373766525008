<script>
import { auth0 } from './auth';
export default {
  data() {
    return {
      isAuthenticated: false
    };
  },
  async created() {
    this.auth0 = auth0;
  }
}
</script>

<template>
  <div class="container is-max-desktop" style="text-align: center;">
    <router-view/>
  </div>
</template>

<style>
#app {
  padding-top: 40px;
}
/*
#app .container {
  max-width: 400px;
}
*/
</style>
