<template>
  <h1>TABILLY</h1>
  <div class="home panel">
    <p class="panel-heading">
      Let's get started
    </p>
    <div class="panel-block">
      <button class="button is-primary" @click="connectGithub">Connect Github</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    // Log the user in
    login() {
      this.$root.auth0.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$root.auth0.logout({
        returnTo: window.location.origin
      });
    },
    connectGithub() {
      this.$router.push('repos');
    }
  }
}
</script>

<style scoped>
  h1 {
    font-family: 'Akaya Kanadaka', cursive;
    font-size: 120px;
  }
</style>
