<template>
  <div class="login"></div>
</template>

<script>
export default {
  name: 'Login',
  beforeCreate() {
    this.$root.auth0.loginWithRedirect();
  }
}
</script>
