<template>
  <div class="logout"></div>
</template>

<script>
export default {
  name: 'Logout',
  beforeCreate() {
    this.$root.auth0.logout({
      returnTo: window.location.origin
    });
  }
}
</script>
