<template>
  <div>
    <img src="../assets/tabilly.png" width="300"/>
  </div>
  <iframe src="https://player.vimeo.com/video/520386177?autoplay=1&muted=1" width="640" height="356" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</template>

<style scoped>
  img {
    margin-top: 40px;
    margin-bottom: 40px;
  }
</style>
